<template>
  <v-container class="full-height">
    <v-row class="full-height" align="center">
      <v-col cols="12">
        <v-card max-width="450" class="mx-auto box-shadow" outlined>
          <v-card-title class="font-weight-bold justify-center secondary white--text">
            <v-icon left dark>mdi-lock-outline</v-icon>
            <span>Reset Password</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-7">
            <v-fade-transition>
              <v-alert v-if="status.error" type="error" border="left" class="mb-7">
                {{ status.error }}
              </v-alert>
            </v-fade-transition>

            <v-alert v-if="status.resetSent" type="success" colored-border border="left">
              {{ status.resetSent }}
            </v-alert>

            <v-form v-else ref="resetForm" @submit.prevent="validateForm()">

              <v-text-field
                v-model="data.email"
                label="Email Address"
                :rules="[rules.required, rules.email]"
                @keyup.enter="validateForm()"
                outlined
                dense
              ></v-text-field>

              <v-btn
                @click="validateForm()"
                :loading="status.validating"
                color="primary white--text"
                depressed
                block
                large
              >Submit</v-btn>

              <div class="mt-3 text-center">
                <div>
                  <router-link :to="{ name: 'Login' }">Back to login page</router-link>
                </div>
              </div>

            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import rules from '@/rules'

export default {
  name: 'ResetPassword',

  metaInfo: {
    title: 'Reset Password'
  },

  data() {
    return {
      rules
    }
  },

  computed: {
    ...mapState({
      data: state => state.login.data,
      status: state => state.login.status,
    })
  },

  methods: {
    validateForm() {
      if (this.$refs.resetForm.validate()) {
        this.$store.dispatch('login/resetPassword')
      }
    }
  },

  created() {
    this.$store.commit('login/resetState')
  }
}

</script>
